import React from 'react';
import { Node } from 'djedi-react';

const pageName = 'school';

const NODES = {
  META_TITLE: <Node uri={`${pageName}/meta/title`}>NIPT-skolen</Node>,
  HEADER_TITLE: <Node uri={`${pageName}/header/title`}>NIPT-skolen</Node>,
  PREAMBLE: (
    <Node uri={`${pageName}/preamble`}>
      Du starter nå en 7 minuters infoguide om NIPT. Tryck på förste lektion for å komme i gang.
    </Node>
  ),
  INTRO_TITLE: (
    <Node uri={`${pageName}/intro/title`}>
      NIPT, den mest exakta fosterdiagnostiken för gravida kvinnor.
    </Node>
  ),
  UNDERSTOOD: <Node uri={`${pageName}/dialog/understood`}>Jeg har forstått.</Node>,
  UNDERSTOOD_SHORT: <Node uri={`${pageName}/dialog/understood-short`}>Forstått</Node>,
  NEXT_LESSON: <Node uri={`${pageName}/dialog/next-lesson`}>Neste</Node>,
  PREVIOUS_LESSON: <Node uri={`${pageName}/dialog/previous-lesson`}>Forrige</Node>,
  PAUSE: <Node uri={`${pageName}/dialog/break`}>Jeg behøver en pause</Node>,
  LESSON_OVERVIEW: <Node uri={`${pageName}/dialog/lesson-overview`}>Leksjonsoversikt</Node>,
  BACK: <Node uri={`${pageName}/dialog/back`}>Tillbake</Node>,
  LESSON_TITLE: <Node uri={`${pageName}/lesson-title`}>Leksjon [title]</Node>,
  LISTEN: (
    <Node title="-1" uri={`${pageName}/listen`}>
      lytt til [title]
    </Node>
  ),
  LESSON_LISTEN: (
    <Node title="-1" uri={`${pageName}/lesson-listen`}>
      Lytt til lekjon [title]
    </Node>
  ),
  ADDENDUM_TITLE: <Node uri={`${pageName}/detailed`}>Vil du dykke dypere?</Node>,
  REFERENCE: <Node uri={`${pageName}/reference`}>referenser</Node>,
  QUESTIONNAIRE_INFO: (
    <Node uri={`${pageName}/questions/info.md`}>
      Hensikten er å forbedre NIPT-skolen. Spørsmålene kan handle om hva du lærte eller våre
      forklaringer. Du trenger ikke føle noe press, du vil kunne fortsette på skolen uansett hva du
      svarer.
    </Node>
  ),
  QUESTIONNAIRE_TITLE: (
    <Node uri={`${pageName}/questions/title`}>Vi vil gjerne stille noen spørsmål</Node>
  ),
  QUESTIONNAIRE_RESULTS: (
    <Node uri={`${pageName}/questions/results`} total="-1" score="-1">
      Du fikk [score] rett av [total] mulige
    </Node>
  ),
  QUESTIONNAIRE_GET_RESULTS: <Node uri={`${pageName}/questions/cta`}>Vis resultat</Node>,
  QUESTIONNAIRE_COMMENT: (
    <Node uri={`${pageName}/questions/comment`}>
      Har du noen tanker om NIPT-skolen du vil dele? (Valgfritt)
    </Node>
  ),
  QUESTIONNAIRE_CORRECT: <Node uri={`${pageName}/questions/correct-answer`}>Rett svar</Node>,
  QUESTIONNAIRE_ANSWERED: <Node uri={`${pageName}/questions/your-answer`}>Ditt svar</Node>,
  LESSON_DEEP_DIVE_OPEN: <Node uri={`${pageName}/deep-dive/open`}>Dykk in</Node>,
  LESSON_DEEP_DIVE_CLOSE: <Node uri={`${pageName}/deep-dive/close`}>Opp til overflaten igjen</Node>,
};

export default NODES;
