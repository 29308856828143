import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import useResizeObserver from 'use-resize-observer';

import classes from './FloatContent.module.css';

type Position = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export type FloatContentProps = {
  children: React.ReactNode;
  floatContent: React.ReactNode;
  // Add more positions if needed :)
  position: Position;
};

// This component is kinda complex since it's hard to position a floating element within a parent
// That's why there are multiple components within this component
//
// I rarely use float, so i don't know all the interactions
// This link talked about some ways to make things float in ways that are not standard
// https://css-tricks.com/float-an-element-to-the-bottom-corner/

const MAX_HEIGHT = 104;

/**
 * Places floating content next to the children
 */
const FloatContent: React.FC<FloatContentProps> = props => {
  const { position } = props;

  if (position === 'bottom-right' || position === 'bottom-left') {
    return <FloatContentBottom {...props} />;
  } else {
    return <FloatContentTop {...props} />;
  }
};

/**
 * Places floating content above the children
 */
function FloatContentTop(props: FloatContentProps) {
  if (props.position !== 'top-right' && props.position !== 'top-left') {
    throw new Error("Position must be 'top-right' or 'top-left'");
  }
  const { children, floatContent, position } = props;

  const floatInnerRef = useRef<HTMLDivElement>(null);

  // I want to have width 50% with max-height MAX_HEIGHT, sadly this is not possible with css
  // so I need to calculate max-width with ts instead
  const [maxWidth, setMaxWidth] = React.useState(0);

  useResizeObserver({
    ref: floatInnerRef,
    onResize: ({ height, width }) => {
      if (height == null || width == null) {
        return;
      }

      const aspectRatio = width / height;
      const newMaxWidth = aspectRatio * MAX_HEIGHT;

      if (newMaxWidth !== maxWidth) {
        setMaxWidth(newMaxWidth);
      }
    },
  });

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <div
          style={{ maxWidth: maxWidth ? `${maxWidth}px` : undefined }}
          className={cx(classes[`position-${position}`], classes.float)}
        >
          <div ref={floatInnerRef} className={classes.floatInner}>
            {floatContent}
          </div>
        </div>
        <div className={classes.childCont}>{children}</div>
      </div>
    </div>
  );
}

/**
 * Places floating content under the children
 */
function FloatContentBottom(props: FloatContentProps) {
  if (props.position !== 'bottom-right' && props.position !== 'bottom-left') {
    throw new Error("Position must be 'bottom-right' or 'bottom-left'");
  }
  const { children, floatContent, position } = props;

  const floatInnerRef = useRef<HTMLDivElement>(null);
  const floatRef = useRef<HTMLDivElement>(null);

  // I want to have width 50% with max-height MAX_HEIGHT, sadly this is not possible with css
  // so I need to calculate max-width with ts instead
  const [maxWidth, setMaxWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  useEffect(() => {
    if (floatInnerRef.current) {
      const { width, height } = floatInnerRef.current.getBoundingClientRect();
      const aspectRatio = width / height;

      setMaxWidth(aspectRatio * MAX_HEIGHT);
    }
  }, [height]);

  useResizeObserver({
    ref: floatInnerRef,
    onResize: ({ height }) => {
      setHeight(height ?? 0);
    },
  });

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <div
          style={{
            width: 0,
            height: `calc(100% - ${height}px)`,
            float: props.position === 'bottom-left' ? 'left' : 'right',
          }}
        />
        <div
          ref={floatRef}
          style={{
            maxWidth: maxWidth ? `${maxWidth}px` : undefined,
          }}
          className={cx(classes[`position-${position}`], classes.float)}
        >
          <div ref={floatInnerRef} className={classes.floatInner}>
            {floatContent}
          </div>
        </div>
        <div className={classes.childCont}>{children}</div>
      </div>
    </div>
  );
}

export default FloatContent;
