import React, { useRef, useState } from 'react';
import Typography from 'components/Typography';
import useEmblaCarousel from 'embla-carousel-react';
import useResizeObserver from 'use-resize-observer';

import classes from './TabsWithText.module.css';

export interface TabsWithTextProps {
  title: string;
  subtitle: string;
  tabs: { title: string; content: JSX.Element }[];
}

const TabsWithText: React.FC<TabsWithTextProps> = props => {
  // Shouldn't happen, but just in case :)
  if (!props.tabs.length) {
    throw new Error("Tabs can't be empty");
  }

  // Index of the tab that should be active
  const [activeTab, setActiveTab] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  useResizeObserver({
    ref,
    onResize: () => {
      setHeight(ref.current?.clientHeight ?? 0);
    },
  });

  // When the tabs don't fit in the screen, we'll enable scrolling
  const emblaContainerRef = useRef<HTMLDivElement>(null);
  const [emblaActive, setEmblaActive] = useState(false);
  useResizeObserver({
    ref: emblaContainerRef,
    onResize: () => {
      if (!emblaContainerRef.current) {
        return;
      }

      const children = Array.from(
        emblaContainerRef.current.querySelectorAll(`.${classes.embla__slide}`),
      );

      // -16 for the right most right padding applied on all children
      const totalWidth = children.reduce((t, v) => t + v.clientWidth, 0) - 4;

      setEmblaActive(totalWidth > emblaContainerRef.current.clientWidth);
    },
  });

  const [emblaRef] = useEmblaCarousel({
    loop: false,
    align: 'start',
    skipSnaps: true,
    containScroll: 'trimSnaps',
    active: emblaActive,
  });

  return (
    <div className={classes.content}>
      <Typography variant="smallheading" component="h2" className={classes.title}>
        {props.title}
      </Typography>
      <Typography variant="faded" component="p" className={classes.subtitle}>
        {props.subtitle}
      </Typography>
      {/* Even though it's unnecessary i use embla's css */}
      <div className={classes.embla} ref={emblaRef}>
        <div ref={emblaContainerRef} className={classes.embla__container}>
          {props.tabs.map((tab, i) => (
            <div key={i} className={classes.embla__slide}>
              <button
                onClick={() => setActiveTab(i)}
                tabIndex={activeTab === i ? -1 : 0}
                className={`${classes.tab} ${activeTab === i ? classes.active : ''}`}
              >
                <span>{tab.title}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.textContainer} style={{ height: `${height}px` }}>
        <div ref={ref} className={classes.text}>
          {props.tabs[activeTab].content}
        </div>
      </div>
    </div>
  );
};

export default TabsWithText;
