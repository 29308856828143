import React from 'react';
import NODES from 'djedi-nodes/generic';

import Link from '../../components/Link';
import Typography from '../../components/Typography';
import styles from './EmployeeBlock.module.css';

const EmployeeBlock: React.FC<Author> = ({ avatar, name, profession, about_page }) => {
  const given_name = name.split(' ')[0];
  return (
    <div className={styles.root}>
      <div className={styles.avatar}>
        <img alt={name} src={avatar} />
      </div>
      <Typography variant="smallheading" className={styles.name}>
        {name}
      </Typography>
      <Typography variant="small" className={styles.profession}>
        {profession}
      </Typography>
      {about_page && (
        <Link color="denim" href={`/${about_page.slug}`} className={styles.about}>
          {React.cloneElement(NODES.READ_MORE_ABOUT, { name: given_name })}
        </Link>
      )}
    </div>
  );
};

export default EmployeeBlock;
