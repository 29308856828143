import React from 'react';
import cx from 'classnames';

import Card from '../Card';
import Container from '../Container';
import Typography from '../Typography';
import styles from './Tooltip.module.css';

export type TooltipProps = {
  text?: string;
  target?: Element;
  offset?: number;
  noPortal?: boolean;
  containerClass?: string;
  onClose?: () => void;
  fixedPosition?: boolean;
};

const Tooltip: React.FC<TooltipProps> = ({
  text,
  target,
  offset = 10,
  containerClass = '',
  onClose,
  fixedPosition = true,
}) => {
  const open = target !== undefined;
  const [position, setPosition] = React.useState({ x: 0, y: 0, below: false });
  const [arrowPosition, setArrowPosition] = React.useState({ x: 0, y: 0 });
  const selfRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const handleScroll = () => {
      updatePosition();
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  const updatePosition = React.useCallback(() => {
    if (target !== undefined && selfRef?.current !== undefined) {
      const targetRect = target?.getBoundingClientRect();
      const ownDimensions = selfRef.current.getBoundingClientRect();

      const shouldGoBelow = targetRect.y - ownDimensions.height < 100;
      const targetY = shouldGoBelow
        ? targetRect.y + targetRect.height + offset
        : Math.max(targetRect.y - ownDimensions.height - offset, 5);
      const targetX = Math.min(
        Math.max(targetRect.x + targetRect.width / 2 - ownDimensions.width / 2, 5),
        window.innerWidth - ownDimensions.width - 5,
      );
      setArrowPosition({
        x: targetRect.x + targetRect.width / 2,
        y: shouldGoBelow
          ? targetRect.y + targetRect.height + offset + (fixedPosition ? 0 : window.scrollY)
          : targetRect.y - offset + (fixedPosition ? 0 : window.scrollY),
      });
      setPosition({
        x: targetX,
        y: targetY + (fixedPosition ? 0 : window.scrollY),
        below: shouldGoBelow,
      });
    }
  }, [target, selfRef, offset, fixedPosition]);

  React.useEffect(() => {
    updatePosition();
  }, [target, selfRef, offset, fixedPosition, updatePosition]);

  React.useEffect(() => {
    const handleClose = e => {
      if (selfRef?.current?.contains(e.target)) {
        return;
      }
      onClose && onClose();
    };
    document.addEventListener('mousedown', handleClose);
    return () => {
      document.removeEventListener('mousedown', handleClose);
    };
  }, [selfRef, onClose, target]);

  const rendered = React.useMemo(
    () => (
      <>
        <Card<HTMLDivElement>
          cardRef={selfRef}
          classNameInner={styles.inner}
          className={cx(styles.root, {
            [styles.fixedRoot]: fixedPosition,
          })}
          padded={false}
          style={{
            top: position.y,
            left: position.x,
          }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Container
            className={cx(styles.container, {
              [containerClass as string]: containerClass !== undefined,
            })}
          >
            <Typography variant="md-serif" className={styles.typography}>
              {text}
            </Typography>
          </Container>
        </Card>
        <span
          style={{
            top: arrowPosition.y,
            left: arrowPosition.x,
          }}
          className={cx(styles.arrow, {
            [styles.arrowAbove]: position.below,
            [styles.arrowBelow]: !position.below,
            [styles.arrowFixed]: fixedPosition,
          })}
        />
      </>
    ),
    [
      position.y,
      position.x,
      position.below,
      arrowPosition.y,
      arrowPosition.x,
      containerClass,
      text,
      fixedPosition,
    ],
  );

  return <div className={cx(styles.wrapper, { [styles.wrapperOpen]: open })}>{rendered}</div>;
};

export default Tooltip;
